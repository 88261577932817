<template>
  <div>
    <v-row>
      <v-col cols="6">
        {{ $t('lead.assignedAgentName') }}
      </v-col>
      <v-col cols="6">
        {{ assignedAgent != null ? assignedAgent.data.name : '' }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('lead.assignedAgentPhone') }}
      </v-col>
      <v-col cols="6">
        {{ assignedAgent != null ? assignedAgent.data.phone_number : '' }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('lead.assignedAgentEmail') }}
      </v-col>
      <v-col cols="6">
        {{ assignedAgent != null ? assignedAgent.data.email : '' }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('lead.assignedAgentSpecializations') }}
      </v-col>
      <v-col cols="6">
        <ul v-if="assignedAgent != null && assignedAgent.data.area_specializations != null">
          <li
            v-for="(specialization, i) in assignedAgent.data.area_specializations.data"
            :key="`specialization-${i}`"
          >
            {{ specialization[0] }}
          </li>
        </ul>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['assignedAgent'],
};
</script>
